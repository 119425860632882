<template>
  <v-container class="px-10">
    <v-row justify="center">
      <v-col md="12">
        <v-btn color="success" class="my-9 text-capitalize" @click="() => {
      readMode = false; item = null; display = true}"
        >{{ $t('hr.vacation.add') }}
          <v-icon>mdi-plus</v-icon> 
        </v-btn>
      </v-col>
      <v-col class="mt-8">
        <h3 class="mb-2">{{ $router.currentRoute.name }}</h3>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          class="mb-3"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table :dark="$store.state.isDarkMode"
          :headers="headers"
          id="table"
          :items="data"
          :loading="$store.state.vacations.loading"
          style="border: 1px solid #999"
          :search="search"
          :options.sync="$store.state.vacations.options"
          :server-items-length="$store.state.vacations.serverTotal"
          disable-sort
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-center">
              <v-icon
                color="red"
                style="cursor: pointer"
                @click="handleDelete(item)"
              >mdi-delete
              </v-icon>
              <v-icon
                color="blue"
                class="mx-2"
                style="cursor: pointer"
                @click="handleEdit(item)"
              >mdi-pencil
              </v-icon>
              <v-icon
                color="blue"
                class="mx-2"
                style="cursor: pointer"
                @click="handleDisplay(item)"
              >mdi-eye
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <VacationModal  v-model="display"  :item="item" :read-mode="readMode"></VacationModal>
  </v-container>
</template>
<script> 
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import VacationModal from "@/views/dashboard/modules/HR/vacations/VacationModal.vue";

export default {
  components: {VacationModal},
  data() {
    return {
      readMode: false,
      search: '',
      display: false,
      item: null,
    
    };
  },
  mounted() {
    this.fetch()
  },
  watch: {
    "$store.state.vacations.options": function () {
      this.fetch()
    },
  },
  computed: {
    ...mapGetters({
      data: 'vacations/data',
      headers: 'vacations/headers'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'vacations/fetch',
      delete: 'vacations/delete'
    }),
    handleDisplay(item) {
      this.item = item
      this.readMode = true
      this.display = true
    },
    async handleDelete(item) {
      await this.delete(item.id)
    },
    handleEdit(item) {
     
      this.item = item
      this.readMode = false
      this.display = true

    },
  },
};
</script>
<style scoped>
#table >>> th, #table >>> td {
  text-align: center !important;
}
</style>

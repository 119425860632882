<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="90%" :value="value" @input="$emit('input', $event)" persistent>
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation
    >
      <v-card>
        <v-card-title class="d-flex justify-center"> 
          <h2>
            {{ $t("hr.vacation.title") }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row justify="center">
              <v-col md="4">
                <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.date')" type="date" outlined
                              v-model="form.document.date">
                </v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field :disabled="readMode" :label="$t('hr.vacation.note')" outlined v-model="form.document.note">
                </v-text-field>
              </v-col>
            </v-row>
            <template v-for="(emp, idx) in form.leaves">
              <v-row class="no-padding">
                <v-col>
                  <v-select :items="employeesContracts" item-text="name" item-value="id" :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.emp-name')"
                                outlined v-model="form.leaves[idx].employee_contract_id">
                  </v-select>
                </v-col>
                <v-col>
                  <v-select  :items="leaves" item-text="name" item-value="id"  :disabled="readMode" :rules="requiredRule()" :label="$t('hr.vacation.type')" outlined
                                v-model="form.leaves[idx].type_id" @input="chooseType(form.leaves[idx].type_id,idx)">
                  </v-select>
                </v-col>
                <v-col >
                  <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.from-date')" type="date" outlined
                                v-model="form.leaves[idx].start_date">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.to-date')" type="date" outlined
                                v-model="form.leaves[idx].end_date">
                  </v-text-field>
                </v-col>
                <v-col v-if="form.leaves[idx].type=='hourly'">
                  <v-text-field :rules="requiredRule()" :disabled="readMode"  :label="$t('hr.vacation.from-hour')" type="time" outlined
                                v-model="form.leaves[idx].start_hour">
                  </v-text-field>
                </v-col>
                <v-col v-if="form.leaves[idx].type=='hourly'">
                  <v-text-field :rules="requiredRule()" :disabled="readMode"  :label="$t('hr.vacation.to-hour')" type="time" outlined
                                v-model="form.leaves[idx].end_hour">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="readMode" :label="$t('hr.vacation.note')" outlined 
                                v-model="form.leaves[idx].note">
                  </v-text-field>
                </v-col>
              </v-row>
              <br>
              <v-divider></v-divider>
              <br>
            </template>
            <v-btn :disabled="readMode" type="button"
                   @click="addAnotherEmployee">{{ $t('add') }}
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="readMode"
            color="green"
            class="white--text"
            depressed
            type="submit"
          >{{ $t("submit") }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            depressed
            @click="$emit('input', false)"
          >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'VacationModal',
  data() {
    return {
      valid: false,
      form: {
        document: {
          name: 'leave',
          date: '',
          note: '',
        },
        leaves: [
          {
            employee_contract_id: '',
            start_date: '',
            end_date: '',
            start_hour: '',
            end_hour: '',
            type_id: '',
            note: '',
            type:'',
          }
        ],
      }
    }
  },
  props: {
   
   readMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.fetchLeaves()
    this.fetchEmplCont()
  },
  methods: {
    ...mapActions({
      store: 'vacations/store',
      update: 'vacations/update',
      fetchLeaves: 'employees/fetchLeaves',
      fetchEmplCont: 'employees/fetchEmployeesContracts',
    }),
    addAnotherEmployee() {
      this.form.leaves.push({
        employee_contract_id: '',
        start_date: '',
        end_date: '',
        start_hour: '',
        end_hour: '',
        type_id: '',
        note: '',
        type: '',
      })
    },
    requiredRule() {
      return requiredRule
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        await this[this.item ? 'update': 'store'](this.form)
        this.$emit('input', false)
      }
    },
    chooseType(item,idx){
      for (let i = 0; i < this.leaves.length; i++){
         if(this.leaves[i].id==item)
           this.form.leaves[idx].type=this.leaves[i].type
      }
      
    },
  },
  computed: {
    ...mapGetters({
      employeesContracts: 'employees/employeesContracts',
      leaves: 'employees/leaves'
    })
  },
  watch: {
    item(nV) {
      this.form = {...this.form, ...nV, document: {...nV}}
    }
  }
}
</script>

<style scoped>
.no-padding >>> div.col {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
</style>
